<template>
	<div class="text-center d-flex w-100">
		<v-select
			v-model="postura[principalKey]"
			:options="numberOptions" 
			class="mx-auto text-center" 
			:clearable="false"
			:searchable="false"
			:reduce="option => option.key"
			placeholder=""
			:disabled="isDisabled"
			@input="handleChange"
			:style="borderStyle"
		></v-select>
	</div>
</template>

<script>
export default {
	props: {
		maxNumber: {
			type: Number,
			required: true,
		},
		isDisabled: {
			type: Boolean,
			required: false,
		},
		postura: {
			type: Object,
			required: true,
		},
		principalKey: {
			type: String,
			required: true,
		},
		secondaryKey: {
			type: String,
			required: true,
		},
		color: {
			type: Boolean,
			default: false,
		},
		isZero: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedNumber: this.postura[this.principayKey],
		};
	},
	computed: {
		numberOptions() {
			return Array.from({ length: this.maxNumber + 1 }, (_, i) => ({
				key: i,
				label: i.toString(),
			}));
		},
		borderStyle() {
			return {
				border: `2px solid ${this.color && this.postura[this.principalKey] > 0 ? '#2980b9' : '#e74c3c'}`, 
				width: `80px`,
			};
		},
	},
	methods: {
		handleChange(value) {
			this.postura[this.principalKey] = value;
			this.postura[this.secondaryKey] = this.maxNumber - value;
			this.$emit("saveUnBorn",this.postura);
		},
	}
};
</script>

<style lang="scss" scoped>
.v-select {
  border-radius: 8px;
}
</style>