<template>
	<RevisionLotesSlot>
		<template v-slot:title>
			<div class="d-flex flex-column flex-md-row justify-content-between align-items-center w-100">
				<div class="container-title bg-primary rounded px-2 py-75">
					<h3 class="mb-0 text-white">LOTE: {{ lote.code }}</h3>
				</div>

				<div class="d-flex gap-x-2">
					<div class="d-flex align-items-center gap-x-1">
						<b-form-input
							@keyup.enter="getLotes()"
							class="mt-1"
							v-model="searchMadrilla"
							placeholder="Buscar por madrilla..."
						></b-form-input>
						<button @click="getLotes()" class="btn btn-primary mt-1">Buscar</button>
					</div>
				</div>
			</div>

			<!-- <p class="text-white">{{ headTable }}</p> -->
		</template>

		<template v-slot:subtitle>
			<div class="d-flex flex-column flex-md-row align-items-md-center">
				<h2 class="mb-0 text-white">ETAPA {{ lote.stage }}</h2>
				<p class="mb-0 font-weight-bolder ml-md-2">{{ lote.title }}</p>
			</div>
		</template>

		<template v-slot:options>
			<div class="d-flex align-items-center justify-content-between gap-x-4">
				<div class="mt-1 mt-md-0">
					<validation-observer ref="form">
						<ValidationProvider class="select-year" rules="required" v-slot="{ errors }">
							<b-form-group label="Nacimiento">
								<!-- <flat-pickr
									v-model="date_dob"
									class="form-control bg-white"
									placeholder="Seleccionar fecha"
									:class="errors[0] ? 'is-invalid' : ''"
								/> -->
								<b-form-datepicker
									v-model="date_dob"
									placeholder="Seleccionar Fecha"
									class="form-control bg-white w-100"
									:class="errors[0] ? 'is-invalid' : ''"
									:date-format-options="{
										year: 'numeric',
										month: 'numeric',
										day: 'numeric',
									}"
									locale="es"
									style="position: relative; z-index: 100; min-width: 150px"
								/>
							</b-form-group>
						</ValidationProvider>
					</validation-observer>
				</div>
				<div class="d-flex align-items-center">
					<!-- {{ checkDrag }} -->
					<b-form-checkbox
						class="mb-0 mt-1"
						@input="refreshLocalStorageDrag"
						v-model="checkDrag"
						switch
						size="xl"
					>
						Arrastrar
					</b-form-checkbox>
					<!-- <p class="mb-0"></p> -->
				</div>

				<button @click="exportPdf" class="btn btn-primary mt-1">
					<FeatherIcon icon="PrinterIcon" class="" />
					Exportar
				</button>
			</div>
		</template>

		<template v-slot:table>
			<div class="container-table-stage">
				<draggable
					class="list-group-items"
					tag="ul"
					v-model="headTable"
					v-bind="dragOptions"
					@start="isDragging = true"
					@end="isDragging = false"
					@change="setLocalStorage"
				>
					<transition-group type="transition" name="flip-list">
						<li class="list-group-item" v-for="element in headTable" :key="element.value">
							<div class="container-table">
								<div class="header-sticky">
									<div class="cell cell-header">
										<!-- <div class="icon-move-2">
											<div class="cir" />
											<div class="cir" />
											<div class="cir" />
											<div class="cir" />
											<div class="cir" />
											<div class="cir" />
											<div class="cir" />
											<div class="cir" />
											<div class="cir" />
											<div class="cir" />
										</div> -->
										<p class="mb-0">{{ element.text }}</p>
									</div>

									<template v-if="element.value == 'madrilla'">
										<div class="cell cell-header"></div>
									</template>

									<template v-if="element.value == 'padrillo'">
										<div class="cell cell-header">
											<p class="mb-0">TOTAL</p>
										</div>
									</template>

									<template v-if="element.value == 'inc'">
										<div class="cell cell-header">
											<p class="mb-0">{{ sumEggs(posturas, "inc") }}</p>
										</div>
									</template>

									<template v-if="element.value == 'nac'">
										<div class="cell cell-header">
											<p class="mb-0">{{ sumEggs(posturas, "nac") }}</p>
										</div>
									</template>

									<template v-if="element.value == 'n_nac'">
										<div class="cell cell-header">
											<p class="mb-0">{{ sumEggs(posturas, "n_nac") }}</p>
										</div>
									</template>

									<template v-if="element.value == 'izq'">
										<div class="cell cell-header">
											<p class="mb-0"></p>
										</div>
									</template>

									<template v-if="element.value == 'der'">
										<div class="cell cell-header">
											<p class="mb-0"></p>
										</div>
									</template>

									<template v-if="element.value == 'new'">
										<div class="cell cell-header">
											<p class="mb-0"></p>
										</div>
									</template>
								</div>

								<template v-if="element.value == 'madrilla'">
									<div class="cell" v-for="(postura, index) in posturas">
										<p class="mb-0">{{ postura.madrilla.alias }} / {{ postura.madrilla.plate }}</p>
									</div>
									<div class="cell cell-footer"></div>
								</template>

								<template v-if="element.value == 'padrillo'">
									<div class="cell" v-for="(postura, index) in posturas">
										<p class="mb-0">{{ postura.padrillo.alias }} / {{ postura.padrillo.plate }}</p>
									</div>
									<div class="cell cell-footer">
										<p class="mb-0">TOTAL</p>
									</div>
								</template>

								<template v-if="element.value == 'inc'">
									<div class="cell" v-for="(postura, index) in posturas">
										<p class="mb-0">{{ postura.inc }}</p>
									</div>
									<div class="cell cell-footer">
										<p class="mb-0">{{ sumEggs(posturas, "inc") }}</p>
									</div>
								</template>

								<template v-if="element.value == 'nac'">
									<div class="cell" v-for="(postura, index) in posturas">
										<!-- <ButtonsCounter
											v-if="postura.inc > 0"
											:postura="postura"
											principalKey="nac"
											secondaryKey="n_nac"
											:isDisabled="status_stage == 3"
											:color="true"
											:maxNumber="postura.inc"
										/> -->
										<NumberSelect 
											v-if="postura.inc > 0"
											:maxNumber="postura.inc" 
											:postura="postura"
											principalKey="nac"
											secondaryKey="n_nac"
											:color="true"
											:isDisabled="status_stage == 3"	
											@saveUnBorn="saveUnBornByPosture"
										/>
									</div>
									<div class="cell cell-footer">
										<p class="mb-0">{{ sumEggs(posturas, "nac") }}</p>
									</div>
								</template>

								<template v-if="element.value == 'n_nac'">
									<div class="cell" v-for="(postura, index) in posturas">
										<p class="mb-0">{{ postura.inc > 0 ? postura.n_nac : "" }}</p>
									</div>
									<div class="cell cell-footer">
										<p class="mb-0">{{ sumEggs(posturas, "n_nac") }}</p>
									</div>
								</template>

								<template v-if="element.value == 'izq'">
									<div class="cell" v-for="(postura, index) in posturas">
										<CombChickenlegsCintillos
											:postura="{
												id: postura.id,
												chickenleg_wich_leg: postura.cintillos.wich_leg,
												chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
												cintillo_color: postura.cintillos.hex_1,
												cintillo_description: postura.cintillos.description_1,
												second_color_color: postura.cintillos.hex_2,
												second_color_description: postura.cintillos.description_2,
												total_minus_infertile_eggs: postura.n_nac + postura.nac,
											}"
											:chickenleg_wich_leg="1"
										/>
									</div>
									<div class="cell cell-footer">
										<p class="mb-0"></p>
									</div>
								</template>

								<template v-if="element.value == 'der'">
									<div class="cell" v-for="(postura, index) in posturas">
										<CombChickenlegsCintillos
											:postura="{
												id: postura.id,
												chickenleg_wich_leg: postura.cintillos.wich_leg,
												chickenleg_qty_cintillos: postura.cintillos.qty_cintillos,
												cintillo_color: postura.cintillos.hex_1,
												cintillo_description: postura.cintillos.description_1,
												second_color_color: postura.cintillos.hex_2,
												second_color_description: postura.cintillos.description_2,
												total_minus_infertile_eggs: postura.n_nac + postura.nac,
											}"
											:chickenleg_wich_leg="2"
										/>
									</div>
									<div class="cell cell-footer">
										<p class="mb-0"></p>
									</div>
								</template>

								<template v-if="element.value == 'new'">
									<div class="cell" v-for="(postura, index) in posturas">
										<p class="mb-0">{{ postura.new_cintillo }}</p>
									</div>
									<div class="cell cell-footer">
										<p class="mb-0"></p>
									</div>
								</template>
							</div>
						</li>
					</transition-group>
				</draggable>
			</div>

			<div>
				<b-card no-body>
					<b-card-header>
						<b-button class="w-100" variant="primary" @click="showAccordion = !showAccordion">
							<span>
								({{ posturasCero.length }}) Posturas no incubadas
								<FeatherIcon v-if="showAccordion === true" icon="ArrowUpIcon" size="16" class="ml-1" />
								<FeatherIcon v-else icon="ArrowDownIcon" size="16" class="ml-1" />
							</span>
						</b-button>
					</b-card-header>
					<b-collapse accordion="my-accordion" role="tabpanel" v-model="showAccordion">
						<b-table-simple responsive sticky-header="60vh" no-border-collapse>
							<b-thead class="sticky-header" style="white-space: nowrap">
								<b-th class="text-center">Padrillo</b-th>
								<b-th class="text-center">Madrilla</b-th>
								<b-th class="text-center">Incubando</b-th>
								<b-th class="text-center">Nuevo</b-th>
							</b-thead>
							<b-tbody>
								<template v-if="posturasCero.length > 0" v-for="(postura, index) in posturasCero">
									<b-tr :key="index">
										<b-td class="text-center">
											<SpecimenPlate :specimen="postura.madrilla" />
										</b-td>
										<b-td class="text-center">
											<SpecimenPlate :specimen="postura.padrillo" />
										</b-td>
										<b-td class="text-center">
											<span>{{ postura.inc }}</span>
										</b-td>
										<b-td class="text-center">
											<b-badge variant="danger" v-if="postura.new_cintillo === 'NO'">NO</b-badge>
											<b-badge variant="success" v-else>SÍ</b-badge>
										</b-td>
									</b-tr>
								</template>
								<b-tr v-if="posturasCero.length === 0">
									<b-td colspan="10" class="text-center" style="height: 80px">
										No hay registros para mostrar
									</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>
					</b-collapse>
				</b-card>
			</div>

			<div>
				<b-form-textarea
					id="textarea"
					v-model="text"
					placeholder="Observaciones"
					rows="3"
					max-rows="6"
				></b-form-textarea>
			</div>
		</template>

		<template v-slot:btns>
			<div class="d-flex justify-content-end mt-1">
				<b-button variant="outline-primary mr-1 " @click="saveUnBorn(status_stage == 3 ? 3 : 4)">
					Guardar
				</b-button>
				<b-button :disabled="status_stage == 3" variant="primary" @click="saveUnBorn(3)">Confirmar</b-button>
			</div>
		</template>
	</RevisionLotesSlot>
</template>

<script>
import lotesService from "@/services/lotes.service"
import ButtonsCounter from "@/components/commons/utilities/ButtonsCounter.vue"
import moment from "moment"
import CombChickenlegsCintillos from "../components/CombChickenlegsCintillos.vue"
import RevisionLotesSlot from "./RevisionLotesSlot.vue"
import draggable from "vuedraggable"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import NumberSelect from "@/views/amg/encaste/lotes/components/NumberSelect.vue";

export default {
	name: "RevisionNacedora",
	props: {
		lote: {
			type: Object,
		},
	},
	components: {
		ButtonsCounter,
		CombChickenlegsCintillos,
		RevisionLotesSlot,
		draggable,
		SpecimenPlate,
		NumberSelect,
	},
	computed: {
		sumEggs: () => (posturas, key) => {
			return posturas.reduce(function (previousValue, currentValue) {
				return previousValue + parseInt(currentValue[key])
			}, 0)
		},
		dragOptions() {
			return {
				animation: 0,
				group: "description",
				disabled: !this.checkDrag,
				ghostClass: "ghost",
			}
		},
	},
	data() {
		return {
			headTable: [],
			bodyTable: [],
			posturas: [],
			posturasCero: [],
			text: "",
			status_stage: 1,
			date_dob: null,
			winScreen: screen.width,
			enabled: true,
			list: [
				{ name: "John", id: 0 },
				{ name: "Joao", id: 1 },
				{ name: "Jean", id: 2 },
			],
			dragging: false,
			checkDrag: true,
			searchMadrilla: null,
			showAccordion: false,
		}
	},
	async mounted() {
		this.initLocalStorage()
		await this.getLotes()
	},
	methods: {
		async getLotes() {
			this.isPreloading()
			const resp = await lotesService.getStagesRevisionNacimiento({
				stageLoteId: this.lote.stage,
				loteId: this.lote.id,
				status_stage_lote_id: this.lote.status_stage_lote_id,
				stageCode: 4,
				search: this.searchMadrilla,
			})

			if (this.lote.stage == 4) {
				this.posturasCero = resp.inc_cero
				this.posturas = resp.inc_plus.map((it) => {
					let data = {}
					if (this.lote.status_stage_lote_id == 2) {
						data = {
							...it,
							inc: parseInt(it.inc),
							nac: it.n_nac_id ? parseInt(it.nac) : 0,
							n_nac: it.n_nac_id ? parseInt(it.n_nac) : (parseInt(it.n_nac) == 0 ? parseInt(it.nac) : parseInt(it.n_nac)),
						}
					} else {
						data = {
							...it,
							inc: parseInt(it.inc),
							nac: parseInt(it.nac),
							n_nac: parseInt(it.n_nac),
						}
					}
					return {
						...data,
					}
				})
			} else {
				this.posturas = resp.map((it) => {
					let data = {}
					if (this.lote.status_stage_lote_id == 2) {
						data = {
							...it,
							inc: parseInt(it.inc),
							nac: 0,
							n_nac: parseInt(it.n_nac) == 0 ? parseInt(it.nac) : parseInt(it.n_nac),
						}
					} else {
						data = {
							...it,
							inc: parseInt(it.inc),
							nac: parseInt(it.nac),
							n_nac: parseInt(it.n_nac),
						}
					}
					return {
						...data,
					}
				})
			}
			this.isPreloading(false)

			this.text = this.posturas[0].observation
			this.status_stage = this.posturas[0].status_stage

			this.date_dob = this.posturas[0].dob
		},
		async saveUnBorn(status) {
			if (!(await this.$refs.form.validate()) && status == 3) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()
			const resp = await lotesService.saveStagesUnBorn({
				posturas: this.posturas,
				text: this.text,
				status,
				loteId: this.lote.id,
				date_dob: this.date_dob,
			})
			await this.$emit("refresh")
			await this.getLotes()
			this.isPreloading(false)
		},
		async saveUnBornByPosture(postura) {
			this.isPreloading()
			await lotesService.saveUnBornByPosture({
				postura,
			})
			this.isPreloading(false)
		},
		async exportPdf() {
			this.isPreloading()
			const resp = await lotesService.exportPdfLoteStageFour({
				stageLoteId: this.lote.stage,
				loteId: this.lote.id,
				status_stage_lote_id: this.lote.status_stage_lote_id,
			})
			this.isPreloading(false)

			const oMyBlob = new Blob([resp], {
				type: "application/pdf",
			}) // the blob
			let url = document.createElement("a")
			url.href = URL.createObjectURL(oMyBlob)
			url.download = `${this.lote.code}-ETAPA${this.lote.stage}-${moment().format("DD-MM-YYYY_HH:mm")}.pdf`
			url.click()
		},
		add: function () {
			this.list.push({ name: "Juan " + id, id: id++ })
		},
		replace: function () {
			this.list = [{ name: "Edgard", id: id++ }]
		},
		checkMove: function (e) {
			window.console.log("Future index: " + e.draggedContext.futureIndex)
		},
		setLocalStorage() {
			localStorage.setItem("lotes-stage-four", JSON.stringify(this.headTable))

			// console.log('ADD', this.headTable);
		},
		initLocalStorage() {
			this.headTable = JSON.parse(localStorage.getItem("lotes-stage-four")) || [
				{ text: "MADRILLA", value: "madrilla", visible: true },
				{ text: "PADRILLO", value: "padrillo", visible: true },
				{
					text: "INCUBANDO",
					value: "inc",
					image: "/lotes/lote-grid-2.svg",
					visible: true,
				},
				{
					text: "NACIERON",
					value: "nac",
					image: "/lotes/lote-grid-3.svg",
					visible: true,
				},
				{
					text: "NO NACIERON",
					value: "n_nac",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "PATA IZQ",
					value: "izq",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "PATA DER",
					value: "der",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
				{
					text: "NUEVO",
					value: "new",
					image: "/lotes/lote-grid-4.svg",
					visible: true,
				},
			]

			this.checkDrag = JSON.parse(localStorage.getItem("check-drag") || true)
		},
		refreshLocalStorageDrag() {
			localStorage.setItem("check-drag", JSON.stringify(this.checkDrag))
		},
	},
}
</script>

<style lang="scss">
.icon-head-posturas {
	margin-right: 0.2rem;
}
.container-cintillos {
	margin-top: 0.5rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;

	.cintillo {
		height: 30px;
		width: 20px;
		background: red;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 64%, 0 100%);
		position: relative;
		margin-right: 0.5rem;

		:last-child {
			margin-right: 0;
		}

		span {
			font-weight: bold;
			position: absolute;
			left: 50%;
			translate: -50%;
			color: #fff;
		}
	}
}
.table-revision-nacedora {
	min-height: 50vh;
}
.list-group-items {
	margin: 0;
	padding: 0;
	margin-bottom: 1rem;
	background: #f4f1f7;
	min-width: 1200px;
	span {
		// background: #fff;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		width: 100%;
		max-height: 400px;
		overflow-y: auto;
		overflow-x: hidden;
		.header-sticky {
			z-index: 20;
			position: sticky;
			top: 0;
		}
		.cell-footer {
			position: sticky;
			bottom: 0;
		}
	}
	span::-webkit-scrollbar {
		width: 4px !important;
		background: #d5d1fb !important;
	}

	span::-webkit-scrollbar-thumb {
		background: #7367f0 !important;
		border-radius: 6px !important;
	}

	.list-group-item {
		// padding: 2rem;
		padding: 0;
		margin: 0;
	}
}
.button {
	margin-top: 35px;
}
.flip-list-move {
	transition: transform 0.5s;
}
.no-move {
	transition: transform 0s;
}
.ghost {
	// opacity: 0.5;
	// background: #c8ebfb;
}
.list-group {
	min-height: 20px;
}
.list-group-item {
	cursor: move;
}
.list-group-item i {
	cursor: pointer;
}

.container-table {
	// background: #f4f1f7;
	.cell {
		// padding: 1rem 1rem;
		// text-align: center;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #ebe9f1;
		z-index: 20;
	}
	.cell-header,
	.cell-footer {
		background: #f4f1f7;
		height: 49px;
		p {
			font-weight: bolder;
		}
	}
	.cell-header {
		position: relative;
		.icon-move-2 {
			margin-left: 5px;
			margin-top: 5px;
			margin-bottom: 5px;
			justify-content: center;
			align-items: center;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 2px;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 15px;
			width: 10px;
			// background: red;
			.cir {
				width: 2px;
				background: #cacaca;
				height: 2px;
				box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
			}
		}
	}
}

.container-table-stage {
	width: 100%;
	overflow: auto;
	padding: 0 0 0.5rem 0;
	// margin: 0 0 .3rem 0;
}
.container-switch {
	background: #e4e3e3;
	width: 100px;
	height: 40px;
	border-radius: 25px;
	position: relative;
	cursor: pointer;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	.barra {
		position: absolute;
		top: 3px;
		left: 3px !important;
		width: 47px;
		height: 34px;
		background: #7367f0;
		border-radius: 34px;
		transition: all 0.3s ease;
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	}
	.barra-text {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// padding: 0 .5rem;
		p {
			font-size: 10px;
			font-weight: bolder;
		}
	}
	.active {
		left: calc(47px) !important;
	}
}

.modal-body {
	max-height: 85vh;
}
</style>
